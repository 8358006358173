import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditOrder from "./AddEditOrder";
import { UseAuth } from '../../Auth/Library/UseAuth';
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllOrders from "../Library/UseGetAllOrders";

const Orders = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedOrderID, SetSelectedOrderID] = useState(null);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        FetchMore,
        GoToPage,
    } = UseGetAllOrders(user.lang);

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("OrderList")}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        options = {{
                            OnEditClick: (manufacturerID) => SetSelectedOrderID(manufacturerID)
                        }}
                    />
                </>
            }

            {selectedOrderID || selectedOrderID == 0 ?
                <AddEditOrder
                    selectedOrderID = {selectedOrderID}
                    SetSelectedOrderID = {SetSelectedOrderID}
                />
            :null}

        </>
    )
}

export default Orders;