import Loading from '../../../GlobalComponents/Loading';
import UseCalendar from '../Library/UseCalendar';
import arrowIcon from '../../../Media/Images/Icons/arrow.webp';

const Calendar = ({OnSelectDate,GetBlockedDays,showLegend,selectedDate,showPast,OnClickOnBlockedDay}) => {

    const {days,month,selectedMonth,year,SetMonth,SelectDate,todayMonth,todayYear,loading} = UseCalendar(GetBlockedDays,selectedDate,showPast,OnClickOnBlockedDay,OnSelectDate);

    return(
        <div className="calendar">
            <div className="header">

                <div className="months">
                    <div className="arrow">
                        {showPast || (!showPast && !(selectedMonth == todayMonth && year == todayYear)) ? 
                            <img
                                src={arrowIcon}
                                onClick={() => SetMonth(selectedMonth - 1)}
                            />
                        :null}
                    </div>
                    <div className="month">{month} {year}</div>
                    <div className="arrow">
                        <img
                            src={arrowIcon}
                            className="right"
                            onClick={() => SetMonth(selectedMonth + 1)}
                        />
                    </div>
                </div>
            </div>
            <div className="days">
                <div className="days-row names">
                    <div className="day">Po</div>
                    <div className="day">Út</div>
                    <div className="day">St</div>
                    <div className="day">Čt</div>
                    <div className="day">Pá</div>
                    <div className="day">So</div>
                    <div className="day">Ne</div>
                </div>
                {!loading ?
                    <>
                        {days.map((item,index) => (
                            <div key={index} className="days-row">
                                {item.map((it,ind) => {

                                    var className = "day";
                                    if(it.isBlocked) className += " blocked";
                                    if(it.isSelected)  className += " selected";
                                    if(it.inPast) className += " in-past";
                                    if(it.day == "") className += " empty";
                                    if(it.isBlocked && OnClickOnBlockedDay) className += " clickable";

                                    return(
                                        <div 
                                            key = {ind} 
                                            title = {it.title}
                                            className={className}
                                            onClick = {() => SelectDate(it)}
                                        >{it.day}</div>
                                    )}
                                )}
                            </div>
                        ))}
                    </>
                :
                    <Loading />
                }
            </div>
            {showLegend ?
                <div className="legend">
                    <div className="days-row">
                        <div className="day selected"></div>
                        <div className="day">Vybraný den</div>
                        <div className="day blocked"></div>
                        <div className="day">Blokovaný den</div>
                    </div>
                </div>
            :null}
        </div>
    )
}

export default Calendar;