import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import List from '../../../../GlobalComponents/List/List';
import ProductFilter from '../../../Eshop/Components/Products/ProductFilter';
import { useState } from 'react';
import ModalFilterPanel from '../../../../GlobalComponents/Modal/ModalFIlterPanel';
import UseChooseProducts from '../../Library/Products/UseChooseProducts';

Modal.setAppElement('#root');

const ChooseProducts = ({
    formData,
    SetShowAddProducts,
    FillFormSelectedItemsWithObj
}) => {

    var selectedProducts = [];
    if(formData.selectedProducts && formData.selectedProducts.length > 0)
    {
        for(let val of formData.selectedProducts)
        {
            selectedProducts.push(val.productID);
        }
    }
    
    const {user} = UseAuth();
    const {t} = useTranslation();

    const [showFilter,SetShowFilter] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        allLinks,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterProducts
    } = UseChooseProducts(user.lang,selectedProducts,FillFormSelectedItemsWithObj);

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddProducts(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddProducts")}
                OnClose ={() => SetShowAddProducts(false)}
                children={
                    <Button 
                        OnClick = {() => SetShowFilter(!showFilter)}
                        className="btn-blue"
                    >{t("Filter")}</Button>
                }
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {showFilter ?
                            <ModalFilterPanel
                                OnClose = {() => SetShowFilter(false)}
                            >
                                <ProductFilter
                                    allLinks = {allLinks}
                                    OnFilter = {(filterData) => FilterProducts(filterData)}
                                />
                            </ModalFilterPanel>
                        :null}

                        <div className="modal-body-no-padding">
                            <List 
                                headerClassName={"no-top-border"}
                                headerData = {headerData} 
                                contentData = {content}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                isSortable = {false}
                                loading = {loading}
                                hideOptions = {true}
                                OnSelectAll = {(e) => SelectAll(e)}
                                options = {{
                                    OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                }}
                            />
                        </div> 
                        
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddProducts(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )
}

export default ChooseProducts;