import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { LOGIN_USER } from '../Queries/auth';
import {useMutation } from '@apollo/client';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import { useTranslation } from 'react-i18next';
import { UseAuth } from './UseAuth';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';

const UseLogin = (params) => {

	const {user,SetUser} = UseAuth();

	const {t} = useTranslation();
    const navigate = useNavigate();

	const {formData, SetFormData} = UseFormHandle({
		email:"",
		password:""
	});
	
	const [disabled,SetDisabled] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

		document.addEventListener("keyup",PressKey);
		return () => {
			document.removeEventListener("keyup",PressKey);
		}

	},[formData])

	useEffect(() => {

		if(user.adminUserID != 0)
		{
			var url = localStorage.getItem("urlToShow");
            localStorage.setItem("urlToShow", "");

			if(url && url != "")
				navigate(url);
			else{
				navigate("/content/links");
			}
		}

	},[user])

    const [LoginUser,{loading}] = useMutation(LOGIN_USER,{

		onCompleted: async (data) => {

			if(!data.AdminLogin.adminUser)
			{
				SetError(t('WronCredentialInserted'));
				setTimeout(() => {SetError(''); SetDisabled(false)},3000);
			}
			else
			{
                params.OnSuccess(data.AdminLogin.loginToken);

				SetUser({
					adminUserID:data.AdminLogin.adminUserID,
					adminRoleID:data.AdminLogin.adminRoleID,
					name:data.AdminLogin.name,
					surname:data.AdminLogin.surname,
					email:data.AdminLogin.email,
					lang:data.AdminLogin.lang,
				})
			}
		},
		onError:(err) => {

			err = GetApolloErrorText(err);

			SetError(err);
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	});

    const Login = () => {

		const {password,email} = formData;

		SetDisabled(true);
						
		if(password !== '' && email !== ''){

			LoginUser({
				variables:{
					email,
					password
				}
			});  
			
		}else{
			SetDisabled(false);
			SetError(t('NotFilledEmailOrPwd'));
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	}

    const PressKey = (e) => {
		if(e.key == "Enter")
        Login();
	}

    return {
        formData,
        disabled,
        error,
		loading,
		SetFormData,
        Login
    }

}

export default UseLogin;