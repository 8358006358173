import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { EDIT_WEB_SETTINGS } from "../../Queries/webSettings";
import { useEffect } from "react";

const UseEditWebSettings = (lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue
    } = UseFormHandle({
        selectedLang:lang,
        langs:[]
    });

    useEffect(() => {
        if(lang)
            SetFormData("selectedLang",lang);
    },[lang])

    const [EditWebSettingsMutation,{error,loading}] = useMutation(EDIT_WEB_SETTINGS,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.EditWebSettings);

            AddNotification(t("SuccessfullyUpdated"),true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const EditWebSettings = () => {

        let langs = [];
        for(const lang of formData.langs)
        {
            langs.push({
                email:                      lang.email,  
                tel:                        lang.tel,             
                telHours:                   lang.telHours,      
                productDetailAssemblyText:  lang.productDetailAssemblyText,
                productDetailYieldText:     lang.productDetailYieldText, 
                overHeaderMessage:          lang.overHeaderMessage,
                lang:                       lang.lang
            });
        }

        EditWebSettingsMutation({
            variables:{
                langs: langs
            }
        })
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        EditWebSettings,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseEditWebSettings;