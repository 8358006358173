import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import ColumnResponziveSettings from "./ColumnResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import Select from "../../../../../GlobalComponents/Select";

const ColumnSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(
        {
            columnCount: 0,
            className:"",
            backgroundColorClass:"",
            contentAlign:"",
            hasBorder:0,
            borderRadius:0,
            colsXL:"25",
            colsLG:"25",
            colsMD:"25",
            colsSM:"25",
            colsXS:"25",
            colsXXS:"25",
            mtXL:0,
            mbXL:0,
            mtLG:0,
            mbLG:0,
            mtMD:0,
            mbMD:0,
            mtSM:0,
            mbSM:0,
            mtXS:0,
            mbXS:0,
            mtXXS:0,
            mbXXS:0,
            ptXXS:0,
            pbXXS:0,
            prXXS:0,
            plXXS:0,
            ptXS:0,
            pbXS:0,
            prXS:0,
            plXS:0,
            ptSM:0,
            pbSM:0,
            prSM:0,
            plSM:0,
            ptMD:0,
            pbMD:0,
            prMD:0,
            plMD:0,
            ptLG:0,
            pbLG:0,
            prLG:0,
            plLG:0,
            ptXL:0,
            pbXL:0,
            prXL:0,
            plXL:0
        }
    ); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection,
        GetColumnCount
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"column");
            const columnCount = GetColumnCount(showSettingsElement.index);

            SetCompleteFormData({
                columnCount:            columnCount,
                className:              settings.className,
                backgroundColorClass:   settings.backgroundColorClass,
                contentAlign:           settings.contentAlign,
                hasBorder:              settings.hasBorder,
                borderRadius:           settings.borderRadius,
                colsXL:                 settings.colsXL,
                colsLG:                 settings.colsLG,
                colsMD:                 settings.colsMD,
                colsSM:                 settings.colsSM,
                colsXS:                 settings.colsXS,
                colsXXS:                settings.colsXXS,
                mtXL:                   settings.mtXL,
                mbXL:                   settings.mbXL,
                mtLG:                   settings.mtLG,
                mbLG:                   settings.mbLG,
                mtMD:                   settings.mtMD,
                mbMD:                   settings.mbMD,
                mtSM:                   settings.mtSM,
                mbSM:                   settings.mbSM,
                mtXS:                   settings.mtXS,
                mbXS:                   settings.mbXS,
                mtXXS:                  settings.mtXXS,
                mbXXS:                  settings.mbXXS,
                ptXXS:                  settings.ptXXS,
                pbXXS:                  settings.pbXXS,
                prXXS:                  settings.prXXS,
                plXXS:                  settings.plXXS,
                ptXS:                   settings.ptXS,
                pbXS:                   settings.pbXS,
                prXS:                   settings.prXS,
                plXS:                   settings.plXS,
                ptSM:                   settings.ptSM,
                pbSM:                   settings.pbSM,
                prSM:                   settings.prSM,
                plSM:                   settings.plSM,
                ptMD:                   settings.ptMD,
                pbMD:                   settings.pbMD,
                prMD:                   settings.prMD,
                plMD:                   settings.plMD,
                ptLG:                   settings.ptLG,
                pbLG:                   settings.pbLG,
                prLG:                   settings.prLG,
                plLG:                   settings.plLG,
                ptXL:                   settings.ptXL,
                pbXL:                   settings.pbXL,
                prXL:                   settings.prXL,
                plXL:                   settings.plXL
            })
        }

    },[showSettingsElement])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"column");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"column");
        ClearSelection();
    }

    var columnIndex = 0;
    if(showSettingsElement.index)
    {
        var colIndex = showSettingsElement.index.split("-");
        columnIndex = colIndex[colIndex.length - 1];
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <ColumnResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <ColumnResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <ColumnResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <ColumnResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <ColumnResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <ColumnResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("ContentAlign")}</label>
                        <Select
                            name = {"contentAlign"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.contentAlign}
                        >
                            <option value={"top"}>{t("ToTop")}</option>
                            <option value={"center"}>{t("ToCenter")}</option>
                            <option value={"bottom"}>{t("ToBottom")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("WithBorder")}</label>
                        <Select
                            name = {"hasBorder"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.hasBorder}
                        >
                            <option value={"0"}>{t("No")}</option>
                            <option value={"1"}>{t("Yes")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Radius")}</label>
                        <Input 
                            type = "number"
                            name = "borderRadius"
                            value = {formData.borderRadius}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("BackgroundColor")}</label>
                        <Select
                            name = {"backgroundColorClass"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.backgroundColorClass}
                        >
                            <option value={""}></option>
                            <option value={"grey"}>Šedá</option>
                            <option value={"red"}>Červená</option>
                            <option value={"brown"}>Hnědá</option>
                            <option value={"black"}>Černá</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            {formData.columnCount > 1 ?
                <div className="cm-remove-section-elm">
                    <Button 
                        className="btn-brown"
                        OnClick = {() => RemoveElm()}
                    >
                        {t("RemoveColumn")}
                    </Button>
                </div>
            :null}
        </>
    )
}

export default ColumnSettings;