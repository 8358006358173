import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins } from "../../Library/functions";
import { useTranslation } from "react-i18next";
import TinyMCEEditor from "../TinyMCEEditor";

const Form = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement,SetSettings} = UseContentManager();
    const {t} = useTranslation();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const OnEditorChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"text",content,"form")
    }
    
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "form"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName +  " " + data.className} style={margins.styles}>
                
                <div className="cm-row cm-wrap cols-padding-20 cols-padding-xs-15">
                    <div className="cm-col cm-col-33 cm-col-xs-100">
                        <div className="cm-form-group">
                            <input placeholder="Jméno" />
                        </div>
                    </div>
                    <div className="cm-col cm-col-33 cm-col-xs-100">
                        <div className="cm-form-group">
                            <input placeholder="Email" />
                        </div>
                    </div>
                    <div className="cm-col cm-col-33 cm-col-xs-100">
                        <div className="cm-form-group">
                            <input placeholder="Tel" />
                        </div>
                    </div>
                    <div className="cm-col cm-col-100">
                        <div className="cm-form-group">
                            <textarea placeholder="Zpráva" />
                        </div>
                    </div>
                </div>
                <div className="cm-d-flex cm-justify-content-center">
                    <button className={"btn btn-primary"} >
                        <TinyMCEEditor 
                            value = {data.text}
                            OnEditorChange={(content) => OnEditorChange(content)}
                            toolbar = 'bold italic forecolor removeformat'
                            forcedRootBlock = {"div"}
                        />
                    </button>
                </div>
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Form;