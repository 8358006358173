const Select = ({children,value,name,className,OnChange}) => {
    return(
        <div className={"input-group" + (className ? " " + className : "")}>
            <select 
                name={name}
                onChange={(e) => OnChange(e)}
                value={value}
            >
                {children}
            </select>
        </div>
    )
}

export default Select;