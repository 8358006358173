import { UseNotification } from "../Library/UseNotification";
import danger from '../../../Media/Images/Icons/danger.webp';
import success from '../../../Media/Images/Icons/success.webp';

const Notifications = () => {

    const {notifications} = UseNotification();

    return(
        <div className="notifications">
            {notifications.map((item,index) => (
                <div key={index} className={"alert" + (item.isSuccess ? " alert-success" : " alert-danger")}>
                    {item.isSuccess ?
                        <img src={success} />
                    :
                        <img src={danger} />
                    }
                    {item.text}
                </div>
            ))}
        </div>
    )
}

export default Notifications;