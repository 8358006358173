import { GetHeaderLinks } from "../GlobalFunctions/header";
import { useState,useEffect } from "react";
import { useQuery,useApolloClient } from "@apollo/client";
import { GET_HEADER_DATA } from "../GlobalQueries/header";

const UseHeader = (SetIsShrink) => {

    const pathName = window.location.pathname;
    const links    = GetHeaderLinks();
    const client   = useApolloClient();

    const [selectedMenu, SetSelectedMenu] = useState(null);
    const [selectedParentID, SetSelectedParentID] = useState(0);

    const {data,loading,error} = useQuery(GET_HEADER_DATA,{
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        for(let item of links)
        {
            if(item.sublinks && item.sublinks.length > 0)
            {
                for(let val of item.sublinks)
                {
                    if(val.link === pathName)
                    {
                        if(!selectedMenu)
                            ShowSubmenu(null,item.id,item.link)

                        SetSelectedParentID(item.id);
                        break;
                    }
                }
            }
        }
        
    })

    const Shrink = () => {
        SetIsShrink((prevValue) => {
            return !prevValue;
        })
    }

    const ShowSubmenu = (e,id,link) => {

        SetSelectedParentID(null);

        if(!link)
        {
            if(e)
            {
                e.preventDefault();
                e.stopPropagation();
            }

            if(selectedMenu === id)
                SetSelectedMenu(-1);
            else
                SetSelectedMenu(id);
        }
        else
            SetSelectedMenu(null);
        
    }

    const DecreaseNotApprovedProductReviewCount = () => {

        const { NotApprovedProductReviewsCount } = client.readQuery({ query: GET_HEADER_DATA });

        var newCount = 0;
        if(NotApprovedProductReviewsCount > 0)
            newCount = NotApprovedProductReviewsCount - 1;
        
        client.writeQuery({ 
            query:GET_HEADER_DATA,
            data:{
                NotApprovedProductReviewsCount: newCount
            } 
        });
    }

    return{
        headerData: data,
        loading,
        links,
        selectedMenu,
        selectedParentID,
        SetSelectedParentID,
        ShowSubmenu,
        Shrink,
        DecreaseNotApprovedProductReviewCount
    }
}

export default UseHeader;