import React, { useEffect } from 'react';
import {Routes, Route } from 'react-router-dom';
import Login from '../Modules/Auth/Components/Login';
import NewPassword from '../Modules/Auth/Components/NewPassword';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Links from '../Modules/Content/Components/Links.js';
import Manufacturers from '../Modules/Eshop/Components/Manufacturers/Manufacturers';
import Parameters from '../Modules/Eshop/Components/Parameters/Parameters';
import ParameterTemplates from '../Modules/Eshop/Components/ParameterTemplates/ParameterTemplates';
import Covers from '../Modules/Eshop/Components/Covers/Covers';
import CoverTitles from '../Modules/Eshop/Components/CoverTitles/CoverTitles';
import Products from '../Modules/Eshop/Components/Products/Products';
import ProductReviews from '../Modules/Eshop/Components/Products/ProductReviews';
import Labels from '../Modules/Eshop/Components/Labels/Labels';
import TickLabels from '../Modules/Eshop/Components/TickLabels/TickLabels';
import Banners from '../Modules/Banner/Components/Banners';
import Layout from '../Layout/Layout.js';
import NoMatch from './NoMatch';
import SocialNetworks from '../Modules/SocialNetwork/Components/SocialNetworks';
import FbPhotos from '../Modules/FbPhotos/Components/FbPhotos';
import WebSettings  from '../Modules/Settings/Components/WebSettings';
import Orders from '../Modules/Orders/Components/Orders';
import Coupons from '../Modules/Eshop/Components/Coupons/Coupons';
import Newsletter from '../Modules/Newsletter/Components/Newsletter';

//import { ContentManagerProvider } from '../Modules/ContentManager/Library/UseContentManager';
/*
import { GET_SETTINGS } from '../GlobalQueries/globalQueries';
import {useQuery} from '@apollo/client';
*/

const Routing = () => {

    /*
    const {data} = useQuery(GET_SETTINGS,{
		fetchPolicy: 'network-only',
	});

    useEffect(() => {
 
        const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
        
        const checkIsIOS = () =>
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        
        if (checkIsIOS()) {
            disableIosTextFieldZoom();
        }

    })

    useEffect(() => {
  
        if(data && data.settings)
        {
            var version = localStorage.getItem("portalVersion");
            if(version != data.settings.portalVersion)
            {
              localStorage.removeItem("au-ap-s");
              localStorage.setItem("portalVersion",data.settings.portalVersion);
              window.location.reload();
            }  
        }
  
    },[data])

    const addMaximumScaleToMetaViewport = () => {

        const el = document.querySelector('meta[name=viewport]');
      
        if (el !== null) {
          let content = el.getAttribute('content');
          let re = /maximum\-scale=[0-9\.]+/g;
      
          if (re.test(content)) {
              content = content.replace(re, 'maximum-scale=1.0');
          } else {
              content = [content, 'maximum-scale=1.0'].join(', ')
          }
      
          el.setAttribute('content', content);
        }
    };
		 */ 
    return (
        <Routes>
        
            <Route exact path='/' element={<Login />}></Route>
            <Route exact path='/reset-password' element={<ResetPassword />}></Route>
            <Route exact path='/new-password/:code' element={<NewPassword />}></Route>
            <Route path='/*' element={<Layout />}>
                <Route exact path='orders' element={<Orders />}></Route>
                <Route exact path='content/links' element={<Links />}></Route>
                <Route exact path='content/banners' element={<Banners />}></Route>
                <Route exact path='content/social-networks' element={<SocialNetworks />}></Route>
                <Route exact path='content/fb-photos' element={<FbPhotos />}></Route>
                <Route exact path='eshop/parameters' element={<Parameters />}></Route>
                <Route exact path='eshop/parameter-templates' element={<ParameterTemplates />}></Route>
                <Route exact path='eshop/categories' element={<Links isEshopCategory = {true} />}></Route>
                <Route exact path='eshop/manufacturers' element={<Manufacturers />}></Route>
                <Route exact path='eshop/covers' element={<Covers />}></Route>
                <Route exact path='eshop/cover-titles' element={<CoverTitles />}></Route>
                <Route exact path='eshop/products' element={<Products />}></Route>
                <Route exact path='eshop/labels' element={<Labels />}></Route>
                <Route exact path='eshop/ticklabels' element={<TickLabels />}></Route>
                <Route exact path='eshop/coupons' element={<Coupons />}></Route>
                <Route exact path='eshop/product-reviews' element={<ProductReviews />}></Route>
                <Route exact path='newsletter' element={<Newsletter />}></Route>
                <Route exact path='settings/web' element={<WebSettings />}></Route>
                <Route exact path='settings/change-password' element={<ChangePassword />}></Route>
                <Route path="*" element={<NoMatch />} />
            </Route>          
	        <Route path="*" element={<NoMatch />} />

	    </Routes>
    );
  
}
export default Routing;