import { useTranslation } from "react-i18next";
import Input from "../../../../../GlobalComponents/Input";
import WindowWidthResponziveInfo from "../WindowWidthResponziveInfo";

const RetinoResponziveSettings = ({size,formData,OnChange}) => {

    const {t} = useTranslation();

    return (
        <>
            <WindowWidthResponziveInfo size={size} />
            
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingTop")}</label>
                        <Input 
                            type = "number"
                            name = {"mt" + size}
                            value = {formData["mt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingBottom")}</label>
                        <Input 
                            type = "number"
                            name = {"mb" + size}
                            value = {formData["mb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RetinoResponziveSettings;