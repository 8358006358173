import { useQuery } from "@apollo/client";
import { GET_ORDER } from "../Queries/order";
import { useState } from "react";

const UseGetOrder = (selectedOrderID,CallBack) => {

    const [orderItems, SetOrderItems] = useState([]);

    const {loading,error} = useQuery(GET_ORDER,{
        variables:{
            param:"orderID",
            value:selectedOrderID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedOrderID != 0)
            {
                CallBack({
                    paymentTypeID:          d.OrderDataBy.paymentTypeID,
                    shippingTypeID:         d.OrderDataBy.shippingTypeID,
                    couponID:               d.OrderDataBy.couponID,
                    orderNumber:            d.OrderDataBy.orderNumber,
                    orderDate:              d.OrderDataBy.orderDate,
                    paymentTypePrice:       d.OrderDataBy.paymentTypePrice,
                    shippingTypePrice:      d.OrderDataBy.shippingTypePrice,
                    email:                  d.OrderDataBy.email,
                    tel:                    d.OrderDataBy.tel,
                    name:                   d.OrderDataBy.name,
                    surname:                d.OrderDataBy.surname,
                    street:                 d.OrderDataBy.street,
                    city:                   d.OrderDataBy.city,
                    zip:                    d.OrderDataBy.zip,
                    countryID:              d.OrderDataBy.countryID,
                    deliveryName:           d.OrderDataBy.deliveryName,
                    deliverySurname:        d.OrderDataBy.deliverySurname,
                    deliveryStreet:         d.OrderDataBy.deliveryStreet,
                    deliveryCity:           d.OrderDataBy.deliveryCity,
                    deliveryZip:            d.OrderDataBy.deliveryZip,
                    deliveryCountryID:      d.OrderDataBy.deliveryCountryID,
                    company:                d.OrderDataBy.company,
                    ic:                     d.OrderDataBy.ic,
                    dic:                    d.OrderDataBy.dic,
                    icdph:                  d.OrderDataBy.icdph,
                    lang:                   d.OrderDataBy.lang,
                    paymentType:            d.OrderDataBy.paymentType,
                    shippingType:           d.OrderDataBy.shippingType,
                    couponAmount:           d.OrderDataBy.couponAmount,
                    couponInPercentage:     d.OrderDataBy.couponInPercentage,
                    couponDiscountPrice:    d.OrderDataBy.couponDiscountPrice,
                    couponCode:             d.OrderDataBy.couponCode
                });
            }
        }
    });

    return{
        loading: (loading),
        error: (error),
        orderItems
    }

}

export default UseGetOrder;