import { useMutation } from "@apollo/client";
import { ADD_EDIT_SOCIAL_NETWORK } from "../Queries/socialNetwork";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditSocialNetwork = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetCompleteFormData,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        socialNetworks:[]
    });

    const [AddEditSocialNetorkMutation,{error,loading}] = useMutation(ADD_EDIT_SOCIAL_NETWORK,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditSocialNetwork);

            const notifyText = t("SuccessfullyUpdated");

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditSocialNetork = () => {

        AddEditSocialNetorkMutation({
            variables:{
                socialNetworks:formData.socialNetworks
            }
        }); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditSocialNetork,
        SetFormDataSelectedItemsWithObj,
        InitFormData,
    }

}

export default UseAddEditSocialNetwork;