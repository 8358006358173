import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation AdminLogin($email: String!,$password: String!) {
    AdminLogin(email: $email,password: $password){
        loginToken
        adminUser{
            adminUserID
            adminRoleID
            name
            surname
            email
            lang
        }
    }
  }
`;

export const GET_ADMIN_USER_CONTEXT = gql`
  query AdminUserContext {
      AdminUserContext{
          adminUserID
          adminRoleID
          name
          surname
          email 
          lang
      }
  }
`;

export const CHANGE_PASSWORD = gql`
    mutation ChangeAdminPassword($oldPassword:String!,$password:String!) {
        ChangeAdminPassword(oldPassword:$oldPassword,password:$password)
    }
`;