import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins, GetMediaDimensionsStyles,GetYoutubeVideoID } from "../../Library/functions";
import { SERVER_URL,CONTENT_MANAGER_VIDEO_URL,CONTENT_MANAGER_VIDEO_POSTER_URL } from "../../../../Config";

const Video = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    var videoConent = null;
    if(data.useDimensions == "1")
        videoConent = GetMediaDimensionsStyles(data);

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "video"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            {data.type == "mp4" ?
                <div 
                    className={data.className + " " + margins.styleClassName + (data.mp4File ? " cm-d-flex cm-justify-content-" + data.mp4Align : " d-flex")} 
                    style={margins.styles}
                >
                    {data.mp4File ?
                        <div className={"cm-video-content " + (videoConent && videoConent.styleClassName)} style={videoConent && videoConent.styles}>
                        
                            <video 
                                key = {data.mp4File}
                                controlsList="nodownload nofullscreen" 
                                controls = {data.controls == "1" ? true : false} 
                                className = {(data.useDimensions == 1  ? "cm-has-dimensions" : "")} 
                                playsInline loop muted autoPlay 
                                poster = {SERVER_URL + "/" + CONTENT_MANAGER_VIDEO_POSTER_URL + "/" + data.mp4FilePoster} 
                                style={borderRadiusStyle}
                            >
                                <source src={SERVER_URL + "/" + CONTENT_MANAGER_VIDEO_URL + "/" + data.mp4File} type="video/mp4"></source>
                            </video>
                            
                        </div>
                    :
                        <div className="cm-start-info">Pro vložení videa použijte ikonku ozubeného kolečka "Nastavení"</div>
                    }
                </div>
            :
                <div 
                    className={"cm-youtube-vimeo-container " + data.className + " " + margins.styleClassName} 
                    style={margins.styles}
                >
                    {data.type == "youtube" ?
                        <iframe className={data.className} src={"https://www.youtube.com/embed/" + GetYoutubeVideoID( "v", data.url ) } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    :
                        <iframe className={data.className} src={"https://player.vimeo.com/video/" + (data.url ? data.url.split("/").pop() : "")} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    }
                </div>
            }

            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Video;