import Modal from 'react-modal';
import { UseContentManager } from '../../Library/UseContentManager';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from "react-i18next";
import Button from '../../../../GlobalComponents/Button';
import FileInput from '../../../../GlobalComponents/FileInput';
import UseUploadVideo from '../../Library/FileManager/UseUploadVideo';
import { useApolloClient } from '@apollo/client';
import Loading from '../../../../GlobalComponents/Loading';

Modal.setAppElement('#root');

const AddVideo = ({showAddVideo,SetShowAddVideo}) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const {openFileManagerData,CloseFileManager} = UseContentManager();
    const {formData,loading,OpenMedia,UploadVideo} = UseUploadVideo(client,() => {
        SetShowAddVideo(false);
    });

    return(
        <Modal
            isOpen={showAddVideo}
            onRequestClose={() => SetShowAddVideo(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content cm-file-manager"}
        >

            <ModalHeader 
                title = {t("AddVideo")}
                OnClose ={() => SetShowAddVideo(false)}
            />

            <div className="modal-body"> 

                {loading ?
                    <Loading />
                :
                    <>
                        <div className="row">
                            <div className="col-50">
                                <div className = "form-group">
                                    <label>{t("Video")}</label>
                                    <FileInput 
                                        OnChange = {(e) => OpenMedia(e,"videoFile")}
                                        label = {(formData.videoFile ? formData.videoFile.name : t("ChooseVideo"))}
                                    />
                                </div>
                            </div>
                            <div className="col-50">
                                <div className = "form-group">
                                    <label>{t("VideoCover")}</label>
                                    <FileInput 
                                        OnChange = {(e) => OpenMedia(e,"posterFile")}
                                        label = {(formData.posterFile ? formData.posterFile.name : t("ChoosePhoto"))}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => UploadVideo()}
                >
                    {t("Add")}
                </Button>
            </div>        

        </Modal>
    )
}

export default AddVideo;