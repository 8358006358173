import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import { useState } from "react";
import AddRelatedProduct from "./AddRelatedProduct";
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllVariants from "../../Library/Products/UseGetAllVariants";
import variantIcon from '../../../../Media/Images/Icons/variants.webp';
import InputCard from "../../../../GlobalComponents/InputCard";
import Button from "../../../../GlobalComponents/Button";
import NoItems from '../../../../GlobalComponents/NoItems';
import { useApolloClient } from '@apollo/client';
import UseDeleteVariants from '../../Library/Products/UseDeleteVariants';
import AddEditVariant from './AddEditVariant';
import UseSortProductVariant from '../../Library/Products/UseSortProductVariant';
import UseCopyProductVariant from '../../Library/Products/UseCopyProductVariant';

const Variants = ({selectedProductID,lang,excludedProductCovers}) => {

    const {t} = useTranslation();
    const client = useApolloClient();

    const [selectedProductVariantID, SetSelectedProductVariantID] = useState(null);

    const {OnDragEnd} = UseSortProductVariant(client,lang,selectedProductID);
    const {CopyVariant}  = UseCopyProductVariant(selectedProductID,(d) => ReFetchVariants(d));
    const {
        content,
        loading,
        error,
        headerData,
        selectedProductVariantIDs,
        SelectRow,
        SelectAll
    } = UseGetAllVariants(lang,selectedProductID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteVariants
    } = UseDeleteVariants(selectedProductVariantIDs,selectedProductID,async (d) => {
        ReFetchVariants();
        SetSelectedProductVariantID(null);
    });

    const ReFetchVariants = async(d) => {
        await client.refetchQueries({
            include: ["AllProductVariants"],
        });
    }

    return(
        <div>
            {selectedProductID && selectedProductID != 0 ?
                <>
                    {error ?
                        <Error text={GetApolloErrorText(error)} />
                    :
                        <>
                            <InputCard
                                title = {t("Variants")}
                                icon = {variantIcon}
                                noBodyPadding = {true}
                                headerChildren = {
                                    <>
                                        <Button
                                            className="btn-primary narrow"
                                            OnClick = {() => SetSelectedProductVariantID(0)}
                                        >
                                        {t("Add")}     
                                        </Button>
                                    
                                        <Button
                                            className="btn-brown narrow"
                                            OnClick = {() => ShowDeleteNotification(true)}
                                        >
                                        {t("Delete")}     
                                        </Button>
                                        
                                    </>
                                }
                            > 
                                <List 
                                    headerData = {headerData} 
                                    contentData = {content}
                                    headerClassName = {"no-top-border"}
                                    isSortable = {true}
                                    loading = {loading}
                                    OnSelectAll = {(e) => SelectAll(e)}
                                    OnDragListRowEnd = {(result) => OnDragEnd(result)}
                                    options = {{
                                        OnEditClick: (productVariantID) => SetSelectedProductVariantID(productVariantID),
                                        OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                        OnCopyClick: (productVariantID) => CopyVariant(productVariantID)
                                    }}
                                />
                            </InputCard>
                        </>
                    }

                    {selectedProductVariantID || selectedProductVariantID == 0 ? 
                        <AddEditVariant
                            productID = {selectedProductID}
                            selectedProductVariantID = {selectedProductVariantID}
                            SetSelectedProductVariantID = {SetSelectedProductVariantID}
                            excludedProductCovers = {excludedProductCovers}
                        />
                    :null}

                    {showDeleteNotification ?
                        <ModalNotification 
                            yesNo={true} 
                            text={t("DoYouReallyWantToDeleteVariants")} 
                            CallBack={DeleteVariants} 
                        />
                    :null}

                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddVariantsSaveBasicInformationsFirst")} 
                />   
            }

        </div>
    )
}

export default Variants;