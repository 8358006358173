import ListContent from "./ListContent";
import ListSortableContent from "./ListSortableContent";
import ListHeader from "./ListHeader";
import Loading from '../Loading';
import NoItems from "../NoItems";
import { useTranslation } from 'react-i18next';

const List = ({
    headerData,
    contentData,
    totalContentLength,
    paggingOffset,
    paggingLimit,
    options,
    hideOptions,
    headerClassName,
    isSortable,
    loading,
    FetchMore,
    GoToPage,
    OnDragListRowEnd,
    OnSelectAll,
    replaceDotsImg,
    ReplaceDotsOnClick
}) => {

    const {t} = useTranslation();

    return(
        <div className = "list">
            <ListHeader 
                data = {headerData} 
                headerClassName = {headerClassName}
                OnSelectAll = {OnSelectAll}
                hideOptions = {hideOptions}
                isSortable = {isSortable}
            />
            <div className="list-content">
                {loading || !contentData ? 
                    <Loading />
                :
                    (contentData && contentData.length > 0 ?
                        (isSortable ?
                            <ListSortableContent 
                                data = {contentData} 
                                options = {options} 
                                hideOptions = {hideOptions}
                                isSortable = {true} 
                                level = {1}
                                OnDragEnd = {OnDragListRowEnd}
                                replaceDotsImg = {replaceDotsImg}
                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                            />
                        :
                            <ListContent 
                                data = {contentData} 
                                options = {options} 
                                hideOptions = {hideOptions}
                                level = {1}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                replaceDotsImg = {replaceDotsImg}
                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                            />
                        )
                    :
                        <NoItems text={t("NoItems")} />
                    )
                }
            </div>
        </div>
    )
}

export default List;