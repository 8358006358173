import { useMutation } from "@apollo/client";
import { ADD_EDIT_LINK } from "../Queries/link";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditLink = (selectedLinkID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj

    } = UseFormHandle({
        selectedLang:lang,
        active:1,
        parentID:0,
        isEshopCategory:0,
        photo:null,
        shortcut:"",
        primarilySortBy:"pri",
        inHeader:1,
        inFooter:0,
        linkID:0,
        isBlog:0,
        showOnlyInStockProducts:0,
        hideMenu:0,
        usePriceFilter:1,
        useDepthFilter:1,
        useWidthFilter:1,
        useInStockFilter:1,
        langs:[],
        selectedParameters:[]
    });

    const [AddEditLinkMutation,{error,loading}] = useMutation(ADD_EDIT_LINK,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.addEditReview);

            const notifyText = (selectedLinkID != 0 ? t("SuccessfullyUpdated") : t("NewLinkIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditLink = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        if(check)
        {
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    name:               lang.name,
                    ownUrl:             lang.ownUrl,   
                    description:        lang.description,             
                    metaTitle:          lang.metaTitle,       
                    metaKeywords:       lang.metaKeywords,                 
                    metaDescription:    lang.metaDescription,  
                    metaImage:          lang.metaImage, 
                    lang:               lang.lang
                });
            }

            var selectedParameters = [];
            if(formData.selectedParameters.length > 0)
                for(let val of formData.selectedParameters)
                {
                    selectedParameters.push(val.parameterID);
                }

            AddEditLinkMutation({
                variables:{
                    linkID:                  selectedLinkID,
                    parentID:                formData.parentID,
                    active:                  parseInt(formData.active),
                    inHeader:                parseInt(formData.inHeader),
                    inFooter:                parseInt(formData.inFooter),
                    isBlog:                  parseInt(formData.isBlog),
                    showOnlyInStockProducts: parseInt(formData.showOnlyInStockProducts),
                    hideMenu:                parseInt(formData.hideMenu),
                    usePriceFilter:          parseInt(formData.usePriceFilter),
                    useDepthFilter:          parseInt(formData.useDepthFilter),
                    useInStockFilter:        parseInt(formData.useInStockFilter),
                    useWidthFilter:          parseInt(formData.useWidthFilter),
                    isEshopCategory:         parseInt(formData.isEshopCategory),
                    photo:                   formData.photo,
                    shortcut:                formData.shortcut,
                    primarilySortBy:         formData.primarilySortBy,
                    langs:                   langs,
                    selectedParameters:      selectedParameters
                }
            })
        }
        else
        {
            AddNotification(t("NoNameNoGo"),false); 
        }
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditLink,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj
    }

}

export default UseAddEditLink;