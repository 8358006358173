import {useQuery} from '@apollo/client';
import { GET_COUPONS } from '../../Queries/coupons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../../GlobalComponents/YesNo';
import dayjs from 'dayjs';

const UseGetAllCoupons = (lang) => {

    const [variables,SetVariables] = useState({
        lang,
        limit:40,
        offset:0,
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCouponIDs,SetSelectedCouponIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_COUPONS,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllCoupons: [...prev.AllCoupons, ...fetchMoreResult.AllCoupons]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCoupons);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("CouponCode"),
            className:"static smaller"
        },
        {
            value: t("Amount"),
            className:"static text-center smaller"
        },
        {
            value: t("Language"),
            className:"static text-center smaller"
        },
        {
            value: t("ByManufacturer"),
            className:"static smaller text-center"
        },
        {
            value: t("ByLink"),
            className:"static smaller text-center"
        },
        {
            value: t("ShowInProductDetail"),
            className:"static text-center"
        },
        {
            value: t("CanBeAppliedToDiscountedProducts"),
            className:"static text-center"
        },
        {
            value: t("LimitedQuantity"),
            className:"static smaller text-center"
        },
        {
            value: t("ForInStockProducts"),
            className:"static text-center smaller"
        },
        {
            value: t("Validity"),
            className:"static text-center"
        },
        {
            value: t("PrivateDescription"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.code,
                        className:"static smaller"
                    },
                    {
                        value:val.amount + (val.inPercentage ? "%" : ""),
                        className:"static text-center smaller"
                    },
                    {
                        value:(val.forLang ? val.forLang.suffix  : t("all")),
                        className:"static text-center smaller"
                    },
                    {
                        value:<YesNo isYes = {!!val.byManufacturer} />,
                        className:"static smaller text-center"
                    },
                    {
                        value:<YesNo isYes = {!!val.byLink} />,
                        className:"static smaller text-center"
                    },
                    {
                        value:<YesNo isYes = {!!val.showInProductDetail} />,
                        className:"static text-center"
                    },
                    {
                        value:<YesNo isYes = {!!val.canBeAppliedToDiscountedProducts} />,
                        className:"static text-center"
                    },
                    {
                        value:<><YesNo isYes = {!!val.isLimitedQuantity} /> {val.isLimitedQuantity ? val.quantity + " " + t("pc.") : ""} </>,
                        className:"static smaller text-center"
                    },
                    {
                        value:<YesNo isYes = {!!val.forInStockProducts} />,
                        className:"static smaller text-center"
                    },
                    {
                        value:<>{dayjs(val.dateFrom).format("DD.MM.YYYY")} <br /> - <br /> {dayjs(val.dateTo).format("DD.MM.YYYY")}</>,
                        className:"static text-center"
                    },
                    {
                        value:val.internalDescription,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.couponID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,couponID) => {

        var checked = e.target.checked;
        var newSelectedCouponIDs = [...selectedCouponIDs];
        const newList = SelectCouponID(content,couponID,checked,newSelectedCouponIDs)

        SetContent(newList);
        SetSelectedCouponIDs(newSelectedCouponIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCouponIDs = [];
        const newList = SelectCouponID(content,"all",checked,newSelectedCouponIDs)

        SetSelectedCouponIDs(newSelectedCouponIDs);
        SetContent(newList);
    }

    const SelectCouponID = (list,couponID,checked,selectedCouponIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(couponID == newList[i].rowID || couponID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCouponIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCouponIDs.length; j++)
                    {
                        if(selectedCouponIDs[j] == newList[i].rowID)
                            selectedCouponIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCouponID(newList[i].subData,couponID,checked,selectedCouponIDs)}
        }

        return newList;
    }

    return{
        content,
        totalContentLength: data && data.AllCouponsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedCouponIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllCoupons;