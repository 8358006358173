import gql from 'graphql-tag';

export const GET_LINK_CONTENT = gql`
    query LinkContent($linkID:ID){
        AllLinkLangs(linkID:$linkID){
            lang
            content  
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            suffix
        }
    }
`;

export const SAVE_LINK_CONTENT = gql`
    mutation SaveLinkContent($linkID:ID,$data:[LinkContentData]){
        SaveLinkContent(linkID:$linkID,data:$data) 
    }
`;

export const GET_SELECTED_CATEGORIES = gql`
    query AllPlainLinksByLinkIDs($linkIDs:[ID],$lang:String){
        AllPlainLinksByLinkIDs(linkIDs:$linkIDs,lang:$lang){
            linkID
            name
        } 
    }
`;

export const GET_SELECTED_PRODUCTS = gql`
    query AllProductsByProductIDs($productIDs:[ID],$lang:String){
        AllProductsByProductIDs(productIDs:$productIDs,lang:$lang){
            productID
            name
        } 
    }
`;