import { useEffect, useState } from 'react';
import move from '../../../Media/Images/Icons/move.webp';
import parentElement from '../../../Media/Images/Icons/parent.webp';
import settings from '../../../Media/Images/Icons/settings.webp';
import copy from '../../../Media/Images/Icons/copy.webp';
import { UseContentManager } from "../Library/UseContentManager";
import { useTranslation } from 'react-i18next';

const ControlsMenu = ({index,showAddContent,showAddColumn,element,elementsCount,position}) => {

    const {t} = useTranslation();
    const {
        SetIndexToAddContent,
        SetShowSettingsElement,
        AddColumn,
        ChangeSectionPosition,
        ClearSelection,
        ChangePosition,
        SelectElement,
        CopySection,
        CopyElement
    } = UseContentManager();

    const [parentIndexes, SetParentIndexes] = useState("");

    useEffect(() => {
        if(index)
        {
            index = index.toString();
            var indexes = index.split("-");
            if(indexes.length > 2)
            {
                indexes.pop();
                SetParentIndexes(indexes.join("-"));
            }
        }
    },[index])

    const ChangePos = (e) => {
        
        if(element === "section")
            ChangeSectionPosition(e.target.value,position);
        else
            ChangePosition(e.target.value,position,element);

        ClearSelection();
    }

    const Copy = (index) => {
        
        if(element === "section")
            CopySection(index);
        else
            CopyElement(index,element);

        ClearSelection();
    }

    var positions = [];
    for(let i = 1; i <= elementsCount; i++)
        positions.push(i);

    
    return(
        <div className={"cm-menu" + (element === "column" ? " column" : "")}>

            {showAddContent ? 
                <div onClick = {() => SetIndexToAddContent(index)} className="cm-button primary">
                    {t("AddContent")}
                </div>
            :null}

            {showAddColumn ? 
                <div onClick = {() => AddColumn(index)} className="cm-button primary">
                    {t("AddColumn")}
                </div>
            :null}

            <div className="cm-button" onClick = {() => SetShowSettingsElement({element,index})}>
                <img 
                    src={settings} 
                    alt={(element === "section" ? t("SectionSettings") :  t("ElementSettings"))} 
                    title={(element === "section" ? t("SectionSettings") :  t("ElementSettings"))} 
                />
            </div>

            <div className="cm-button" onClick = {() => Copy(index)}>
                <img 
                    src={copy} 
                    alt = {t("CopyElement")} 
                    title = {t("CopyElement")} 
                />
            </div>

            {parentIndexes != "" ?
                <div className="cm-button" onClick = {() => SelectElement(null,parentIndexes)}>
                    <img src={parentElement} alt="" title = {t("ParentElement")} />
                </div>
            :null}

            <div className="cm-button cm-no-cursor">
                <img 
                    src = {move} 
                    alt = {t("Position")} 
                    title = {t("Position")} 
                />
                <select 
                    value = {position} 
                    onChange = {(e) => ChangePos(e)}
                >
                    {positions.map((item,index) => (
                        <option key = {index}>{item}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}
export default ControlsMenu;