import { useMutation } from "@apollo/client";
import { COPY_PRODUCT_VARIANT } from "../../Queries/products";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseCopyProductVariant = (productID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [CopyProductVariant] = useMutation(COPY_PRODUCT_VARIANT,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d); 

            const notifyText = t("NowIHaveTwin");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    
    const CopyVariant = (productVariantID) => {

        CopyProductVariant({
            variables:{
                productID,
                productVariantID
            }
        })
    } 

    return {
        CopyVariant
    }

}

export default UseCopyProductVariant;