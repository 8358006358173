import { useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_SELECTED_PRODUCTS } from "../Queries/contentManager";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseEditRecomendedProducts = (
    lang,
    selectedProducts
) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

        if(selectedProducts)
        {
            const contentData = GetContentData(selectedProducts);
            SetContent(contentData);
        }

    },[selectedProducts])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }
            cData.rowID         = val.productID;
            cData.selected      = false;

            contentData.push(cData)
            
        }

        return contentData;
    }

    const GetSelectedProducts = async (selectedProducts) => {
        
        var newSelectedProducts = [];
        if(selectedProducts && selectedProducts.length > 0)
        {
            SetLoading(true);
            
            try{
                var productListData = await client.query({
                    query: GET_SELECTED_PRODUCTS,
                    variables:{
                        lang:lang,
                        productIDs: selectedProducts
                    },
                    fetchPolicy:"network-only"
                });

                if(productListData && productListData.data.AllProductsByProductIDs)
                    for(let val of productListData.data.AllProductsByProductIDs)
                    {
                        newSelectedProducts.push({
                            productID:val.productID,
                            name:val.name
                        })
                    }

            }
            catch(err)
            {
                SetLoading(false);
                SetError(GetApolloErrorText(err));
            }

            SetLoading(false);
        }

        return newSelectedProducts;
    }

    return{
        headerData,
        content,
        loading,
        error,
        GetSelectedProducts
    }

}

export default UseEditRecomendedProducts;