import { useEffect, useRef,useReducer } from "react";
import { createPortal } from 'react-dom';

const Iframe = ({children}) => {

    var iframeRef = useRef();
    var iframeBody = useRef();
    var iframeHead = useRef();

    const [_, ForceUpdate] = useReducer((x) => x + 1, 0);

    const HandleLoad = () => {
        iframeBody.current = iframeRef.current.contentDocument.body;
        iframeHead.current = iframeRef.current.contentDocument.head;
        ForceUpdate();
    };

    useEffect(() => {
        iframeRef.current.addEventListener("load", HandleLoad);

        return () => {
            if(iframeRef.current)
                iframeRef.current.removeEventListener("load", HandleLoad);
        }
    },[])

    return(
        <iframe srcDoc={`<!DOCTYPE html>`} ref={iframeRef}>
            {iframeBody.current &&
                createPortal(children,iframeBody.current)
            }
            {iframeHead.current &&
                createPortal(
                    <>
                        <link rel="preconnect" href="https://fonts.googleapis.com" />
                        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"true"} />
                        <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800&display=swap" rel="stylesheet" />
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManager.css?3"}></link>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManagerLG.css?3"}></link>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManagerMD.css?3"}></link>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManagerSM.css?3"}></link>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManagerXS.css?3"}></link>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManagerXXS.css?3"}></link>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/web.css?3"}></link>
                        
                    </>
                ,iframeHead.current)
            }
        </iframe>
    )
}

export default Iframe;