import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_WEB_SETTINGS } from "../../Queries/webSettings";

const UseGetWebSettings = (CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);

    const {loading,error} = useQuery(GET_WEB_SETTINGS,{
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                email:"",                   
                tel:"", 
                telHours:""                                 
            }

            let langs = CompleteLangsArray(d.WebSettingsWithLang.langs,d.AllLanguageMutations,emptyLang);

            CallBack({
                langs:langs
            });
            
            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading,
        error,
        allLanguageMutations,
    }

}

export default UseGetWebSettings;