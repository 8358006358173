import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useMutation } from "@apollo/client";
import { UPLOAD_VIDEO,GET_ALL_VIDEOS } from "../../Queries/fileManager";
import { ValidateImageMimeType, ValidateSize } from "../../../../GlobalFunctions/images";
import { ValidateVideoMimeType, ValidateSize as ValidateVideoSize } from "../../../../GlobalFunctions/videos";
import { useTranslation } from "react-i18next";
import { MAX_IMAGE_FILE_SIZE_TO_UPLOAD, MAX_VIDEO_FILE_SIZE_TO_UPLOAD } from "../../../../Config";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";

const UseUploadVideo = (client,OnSuccess) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const {formData,OpenMedia} = UseFormHandle({
        videoFile:null,
        posterFile:null
    });

    const [UplVideo,{loading,error}] = useMutation(UPLOAD_VIDEO,{
        onCompleted:(d) => {

            const { AllContentManagerVideos } = client.readQuery({ query: GET_ALL_VIDEOS });
            
            var newArray = [d.UploadContentManagerVideo,...AllContentManagerVideos];

            client.writeQuery({ 
                query:GET_ALL_VIDEOS,
                data:{
                    AllContentManagerVideos: newArray
                } 
            });

            if(OnSuccess)
                OnSuccess();
        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const UploadVideo = async () => {

        if(formData.videoFile && formData.posterFile)
        {
            var checkImage = true;
            var isImg = await ValidateImageMimeType(formData.posterFile);

            if(!isImg)
                checkImage = false;

            if(checkImage)
            {
                if(ValidateVideoMimeType(formData.videoFile))
                {
                    if(ValidateSize(formData.posterFile))
                    {
                        if(ValidateVideoSize(formData.videoFile))
                        {
                            UplVideo({
                                variables:{
                                    videoFile:formData.videoFile,
                                    posterFile: formData.posterFile
                                }
                            })
                        }else
                            AddNotification(t("MaxAllowedVideoFileSizeIs") + " " + (MAX_VIDEO_FILE_SIZE_TO_UPLOAD / 1000000) + "MB",false);
                    
                    }else
                        AddNotification(t("MaxAllowedImageFileSizeIs") + " " + (MAX_IMAGE_FILE_SIZE_TO_UPLOAD / 1000000) + "MB",false);
                }else
                    AddNotification(t("WrongVideoFormat"),false);
            }else
                AddNotification(t("VideoCoverIsNotImage"),false);
        }else
            AddNotification(t("VideoOrPosterAreNotChoosen"),false);
    }

    return{
        formData,
        loading,
        UploadVideo,
        OpenMedia
    }
}

export default UseUploadVideo;