import Input from "../../../GlobalComponents/Input";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import { GET_SOCIAL_NETWORKS } from "../Queries/socialNetwork";
import { useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import Error from "../../../GlobalComponents/Error";
import UseAddEditSocialNetworks from "../Library/UseAddEditSocialNetworks";
import { useEffect } from "react";

const SocialNetworks = () => {

    const {t} = useTranslation();

    const {data,loading,error} = useQuery(GET_SOCIAL_NETWORKS,{
        fetchPolicy:"network-only"
    })

    const {
        formData,
        loading:addLoading,
        AddEditSocialNetork,
        SetFormDataSelectedItemsWithObj,
        InitFormData
    } = UseAddEditSocialNetworks();

    useEffect(() => {

        if(data)
        {
            var sNetworks = [];
            for(let val of data.AllSocialNetworks)
            {
                sNetworks.push({
                    socialNetworkID: val.socialNetworkID,
                    type: val.type,
                    url: val.url
                })
            }

            InitFormData({socialNetworks:sNetworks});
        }

    },[data])

    return(
        <>
            <ContentHeader
                title = {t("SocialNetworks")}
                OnSaveButtonClick = {() => AddEditSocialNetork()}
            />
            <ContentBody>

                {error ?
                    <Error text = {GetApolloErrorText(error)} />
                :
                    (loading || addLoading ?
                        <Loading />
                    :
                        (formData.socialNetworks.map((item) => (
                            <div className="form-group" key={item.socialNetworkID}>
                                <label className="input-label">{item.type}</label>
                                <div className="form-group">
                                    <Input 
                                        className="form-control" 
                                        type="text" 
                                        name="url"
                                        placeholder={t("Url")}
                                        OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"socialNetworks","socialNetworkID",item.socialNetworkID)}
                                        value={item.url}
                                    />
                                    
                                </div>
                            </div>
                        )))                                                                     
                    )
                }
            </ContentBody>
        </>
    )
}

export default SocialNetworks;