import { useState,Fragment, useRef,useEffect } from "react";
import infoIcon from '../Media/Images/Icons/info.webp';
import arrow from '../Media/Images/Icons/arrow.svg';

const InputCard = ({
    className,
    noBodyPadding,
    children,
    title,
    icon,
    tabsData,
    topMargin,
    headerChildren,
    headerInfoContent,
    canBeMinimalized,
    reload
}) => {

    const bodyRef = useRef(); 
    const [selectedTab,SetSelectedTab] = useState(0);
    const [closeBody,SetCloseBody] = useState(false);
    const [overflowHidden,SetOverflowHidden] = useState(false);
    const [bodyHeight,SetBodyHeight] = useState("auto");

    const OnHeaderClick = () => {
        if(canBeMinimalized)
        {
            SetCloseBody(!closeBody);
            if(!closeBody)
                SetOverflowHidden(true)
            else
                setTimeout(() => SetOverflowHidden(false) ,600);
        }
    }

    useEffect(() => {

        if(canBeMinimalized)
        {
            SetOverflowHidden(true);
            setTimeout(() => SetOverflowHidden(false) ,600);
        }
        
        if(bodyRef.current && canBeMinimalized)
            SetBodyHeight(bodyRef.current.offsetHeight);

    },[children,bodyRef.current,reload])

    return(
        <div className={"input-card" + (topMargin ? " tm" : "") +  (className ? " " + className : "")}>
            <div 
                onClick = {() => OnHeaderClick()}
                className = {"input-card-header" + (canBeMinimalized ? " cursor-pointer" : "")}
            >
                <h3>
                    {icon ? 
                        <img src={icon} />
                    :null}
                    {title}
                </h3>
                {headerInfoContent ?
                    <div className="info-container">
                        <img className="info-icon" src = {infoIcon} alt="" />
                        <div className="info-content">
                            {headerInfoContent.map((item,index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                :null}
                {headerChildren || canBeMinimalized ?
                    <div className="ml-auto d-flex align-items-center">
                        {headerChildren}
                        {canBeMinimalized ?
                            <img className = {"minimalized" + (closeBody ? " closed" : "")} src={arrow} />
                        :null}
                    </div>
                :null}
            </div>
            {tabsData ?
                <ul className="tabs">
                    {tabsData.map((item,index) => (
                        <li 
                            key = {index}
                            className={(selectedTab === index ? "selected" : "")} 
                            onClick={() => SetSelectedTab(index)}
                        >
                            {item.tabContent}
                        </li>
                    ))}
                </ul>
            :null}
            {children || tabsData ?
                <div 
                    className={
                        "input-card-body " + 
                        (noBodyPadding ? "no-padding" : "") +
                        (closeBody ? " closed" : "") +
                        (canBeMinimalized ? " can-be-minimalized" : "") +
                        (overflowHidden ? " overflow-hidden" : "")
                    }
                    style={(bodyHeight != "auto" && canBeMinimalized  ? {height:bodyHeight + "px"} : null)}
                >
                    <div className = {"input-card-body-content" + (noBodyPadding ? " only-bottom-padding" : "")} ref = {bodyRef}>
                        {tabsData ?
                            tabsData.map((item,index) => {
                                if(selectedTab === index)
                                    return(
                                        <Fragment key = {index}>
                                            {item.content}
                                        </Fragment>
                                    )
                            })
                        :
                            (children)
                        }
                    </div>
                </div>
            :null}
        </div>
    )
}
export default InputCard;