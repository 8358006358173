import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import VideoResponziveSettings from "./VideoResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { IMAGE } from "../../../Library/elements";
import Select from "../../../../../GlobalComponents/Select";

const VideoSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(IMAGE.image); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection,
        SetOpenFileManagerData
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"video");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const SetS = (e,val) => {
        SetFormData(e,val);
        SetSettings(showSettingsElement.index,e,val,"video");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"video");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <VideoResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <VideoResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <VideoResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <VideoResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <VideoResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <VideoResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className = "row">

                <div className="col-100">
                    <div className="form-group">
                        <label>{t("VideoType")}</label>
                        <Select
                            name = {"type"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.type}
                        >
                            <option value={"youtube"}>{t("Youtube")}</option>
                            <option value={"vimeo"}>{t("Vimeo")}</option>
                            <option value={"mp4"}>{t("Mp4")}</option>
                        </Select>
                    </div>
                </div>
                {formData.type == "mp4" ?
                    <>
                        <div className="col-100">
                            <div className="form-group">
                                {formData.mp4File ?
                                    <div className = "row">
                                        <div className = "col-50">
                                            <Button 
                                                className="btn-primary w-100"
                                                OnClick = {() => SetOpenFileManagerData({
                                                    element:"video",
                                                    type:2,
                                                    index:showSettingsElement.index,
                                                    CallBack: ({name,poster}) => {
                                                        SetS("mp4File",name);
                                                        SetS("mp4FilePoster",poster);
                                                    }
                                                })}
                                            >{t("ChangeVideo")}</Button>
                                        </div>
                                        <div className = "col-50">
                                            <Button 
                                                className="btn-brown w-100"
                                                OnClick = {() => {
                                                    SetS("mp4File","");
                                                    SetS("mp4FilePoster","");
                                                }}
                                            >{t("RemoveVideo")}</Button>
                                        </div>
                                    </div>
                                :
                                    <Button 
                                        className="btn-primary w-100"
                                        OnClick = {() => SetOpenFileManagerData({
                                            element:"video",
                                            type:2,
                                            index:showSettingsElement.index,
                                            CallBack: ({name,poster}) => {
                                                SetS("mp4File",name);
                                                SetS("mp4FilePoster",poster);
                                            }
                                        })}
                                    >{t("ChooseVideo")}</Button>
                                }
                            </div>
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("VideoAlignType")}</label>
                                <Select
                                    name = {"mp4Align"}
                                    OnChange = {(e) => SetS(e)}
                                    value = {formData.mp4Align}
                                >
                                    <option value={"start"}>{t("Left")}</option>
                                    <option value={"center"}>{t("Center")}</option>
                                    <option value={"end"}>{t("Right")}</option>
                                </Select>
                            </div>
                        </div>

                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("ShowControls")}</label>
                                <Select
                                    name = {"controls"}
                                    OnChange = {(e) => SetS(e)}
                                    value = {formData.controls}
                                >
                                    <option value={"1"}>{t("Yes")}</option>
                                    <option value={"0"}>{t("No")}</option>
                                </Select>
                            </div>
                        </div>  

                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("Radius")}</label>
                                <Input 
                                    type = "number"
                                    name = "borderRadius"
                                    value = {formData.borderRadius}
                                    OnChange = {(e) => SetS(e)}
                                />
                            </div>
                        </div>

                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("UseDimensions")}</label>
                                <Select
                                    name = {"useDimensions"}
                                    OnChange = {(e) => SetS(e)}
                                    value = {formData.useDimensions}
                                >
                                    <option value={"1"}>{t("Yes")}</option>
                                    <option value={"0"}>{t("No")}</option>
                                </Select>
                            </div>
                        </div>   
                    </> 
                :            
                    <div className="col-100">
                        <div className="form-group">
                            <label>{t("Url")}</label>
                            <Input 
                                type = "text"
                                name = "url"
                                value = {formData.url}
                                OnChange = {(e) => SetS(e)}
                            />
                        </div>
                    </div>
                }
                
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
    
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default VideoSettings;