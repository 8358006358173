import { useMutation } from "@apollo/client";
import { EDIT_FB_PHOTOS } from "../Queries/fbPhotos";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseEditFbPhotos = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData,
        SetFormData,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        fbPhotos:[]
    });

    const [EditFbPhotosMutation,{error,loading}] = useMutation(EDIT_FB_PHOTOS,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.EditFbPhotos);

            const notifyText = t("SuccessfullyUpdated");

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const EditFbPhotos = () => {

        var fbPhotos = [];
        for(let val of formData.fbPhotos)
        {
            fbPhotos.push({
                fbPhotoID: val.fbPhotoID,
                url:val.url,
                isUpdated:val.isUpdated
            })
        }

        EditFbPhotosMutation({
            variables:{
                fbPhotos
            }
        }); 
    } 

    return {
        error: error,
        loading: loading,
        formData,
        SetFormData,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsWithObj,
        EditFbPhotos
    }

}

export default UseEditFbPhotos;