import { useQuery } from "@apollo/client";
import { GET_BANNER,GET_BANNERS } from "../Queries/banner";
import { useState } from "react";
import { CompleteLangsArray } from "../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import { SERVER_URL,BANNER_IMAGE_URL} from '../../../Config/index';

const UseGetBanner = (selectedBannerID,lang,CallBack) => {

    const {t} = useTranslation();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allBannerTypes, SetAllBannerTypes] = useState([]);

    const {loading,error} = useQuery(GET_BANNER,{
        variables:{
            bannerID:selectedBannerID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",   
                image:null,                 
                description:"", 
                price:"",                   
                buttonName:"",                    
                discount:"" ,                    
                url:""                                 
            }

            if(selectedBannerID != 0)
            {
                const uploadParamNameUrl = SERVER_URL + "/" + BANNER_IMAGE_URL + "/stredni_";
                let langs = CompleteLangsArray(d.BannerWithLangs.langs,d.AllLanguageMutations,emptyLang,"image",uploadParamNameUrl);

                CallBack({
                    selectedLang : (d.BannerWithLangs.forLang != "" ? d.BannerWithLangs.forLang  : lang),
                    bannerTypeID: d.BannerWithLangs.bannerTypeID,
                    forLang: d.BannerWithLangs.forLang,
                    seconds: d.BannerWithLangs.seconds,
                    active: d.BannerWithLangs.active,
                    langs:langs
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs,
                    bannerTypeID:0,
                    forLang:"",
                    seconds:0,
                    active:1
                });
            }

            SetAllBannerTypes(d.AllBannerTypes);
            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allBannerTypes
    }

}

export default UseGetBanner;