import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import Input from '../../../GlobalComponents/Input';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import FileInput from '../../../GlobalComponents/FileInput';
import UseAddEditBanner from '../Library/UseAddEditBanner';
import UseGetBanner from '../Library/UseGetBanner';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import { SERVER_URL, BANNER_IMAGE_URL } from '../../../Config';
import Select from '../../../GlobalComponents/Select';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import InputCard from '../../../GlobalComponents/InputCard';
import RadioButton from '../../../GlobalComponents/RadioButton';

Modal.setAppElement('#root');

const AddEditBanner = ({selectedBannerID,SetSelectedBannerID}) => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();

    const {
        loading,
        error,
        formData,
        AddEditBanner,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage
    } = UseAddEditBanner(selectedBannerID,user.lang,(d) => OnAddEditSuccess(d));

    const {
        allLanguageMutations,
        allBannerTypes,
        loading:getLoading,
        error:getError
    } = UseGetBanner(selectedBannerID,user.lang,(d) => {
        
        var initData = {...d,mainPhoto : d.mainPhoto ? SERVER_URL + "/" + BANNER_IMAGE_URL + "/stredni_" + d.mainPhoto : ""};
        InitFormData(initData);

    });

    const OnAddEditSuccess = async (d) => {
        SetSelectedBannerID(null);
        await client.refetchQueries({
            include: ["AllBanners"],
        });
    }

    const SetForLang = (lang) => {

        SetFormData("forLang",lang);
        if(lang != "")
            SetFormData("selectedLang",lang);
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedBannerID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedBannerID === 0 ? t("AddBanner") : t("EditBanner") )}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedBannerID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>  
                                <div className="row">
                                    <div className="col-40 d-flex">
                                        <InputCard
                                            title = {t("Image") + " (" + formData.selectedLang + ")"}
                                            icon = {imageIcon}
                                            className = {"form-group flex-1"}
                                            topMargin={true}
                                        > 
                                            {GetFormLangValue("imageShow") ?
                                                <div className="open-image-container">
                                                    <img className="no-margin" src={GetFormLangValue("imageShow")} />
                                                </div>
                                            :null}

                                            <div className="form-group">                
                                                <FileInput
                                                    name = "image"
                                                    lang = {formData.selectedLang}
                                                    OnChange = {(e) => OpenLangImage(e)}
                                                />
                                            </div>
                                        
                                        </InputCard>
                                       
                                    </div>
                                    <div className={"col-60"}>
                                        <div className="row">
                                            
                                            <div className={"col-100"}>
                                                <div className="form-group">
                                                    <label>*{t("BannerType")}</label>
                                                    <Select 
                                                        value={formData.bannerTypeID}
                                                        OnChange={(e) => SetFormData(e)}
                                                        name = "bannerTypeID"
                                                    >
                                                        <option value={0}>{t("---choose---")}</option>
                                                        {allBannerTypes.map((item) => (
                                                            <option 
                                                                key={item.bannerTypeID} 
                                                                value={item.bannerTypeID}
                                                            >{item.name}</option>
                                                        ))}

                                                    </Select>
                                                </div>
                                            </div>

                                            <div className={"col-50"}>
                                                        
                                                <div className="form-group">
                                                    <label>
                                                        {t("Name")} ({formData.selectedLang})
                                                    </label>
                                                    <Input
                                                        name="name"
                                                        value={GetFormLangValue("name")}
                                                        OnChange={(e) => SetFormLangData(e)}
                                                    />
                                                </div>
                                            </div>

                                            {formData.bannerTypeID != 3 ?
                                                <>
                                                    <div className={"col-50"}>
                                                                
                                                        <div className="form-group">
                                                            <label>
                                                                {t("Price")} ({formData.selectedLang})
                                                            </label>
                                                            <Input
                                                                name="price"
                                                                value={GetFormLangValue("price")}
                                                                OnChange={(e) => SetFormLangData(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={"col-50"}>
                                                                
                                                        <div className="form-group">
                                                            <label>
                                                                {t("Discount")} ({formData.selectedLang})
                                                            </label>
                                                            <Input
                                                                name="discount"
                                                                value={GetFormLangValue("discount")}
                                                                OnChange={(e) => SetFormLangData(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={"col-50"}>
                                                                
                                                        <div className="form-group">
                                                            <label>
                                                                {t("ButtonName")} ({formData.selectedLang})
                                                            </label>
                                                            <Input
                                                                name="buttonName"
                                                                value={GetFormLangValue("buttonName")}
                                                                OnChange={(e) => SetFormLangData(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={"col-100"}>
                                                                
                                                        <div className="form-group">
                                                            <label>
                                                                {t("Description")} ({formData.selectedLang})
                                                            </label>
                                                            <Input
                                                                name="description"
                                                                value={GetFormLangValue("description")}
                                                                OnChange={(e) => SetFormLangData(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div className="col-50">
                                                        <div className="form-group">
                                                            
                                                            <label>{t("Active")} </label>
                                                            <div className="radio-container">
                                                                <RadioButton
                                                                    text="Ano"
                                                                    name="active"
                                                                    id="active-1"
                                                                    value={1}
                                                                    checked = {formData.active === 1 ? true : false}
                                                                    OnChange={(e) => SetFormData(e)}
                                                                />
                                                                <RadioButton
                                                                    text="Ne"
                                                                    name="active"
                                                                    id="active-2"
                                                                    value={0}
                                                                    checked = {formData.active === 0 ? true : false}
                                                                    OnChange={(e) => SetFormData(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"col-50"}>
                                                                
                                                        <div className="form-group">
                                                            <label>
                                                                {t("NumberOfSecondToExecute")}
                                                            </label>
                                                            <Input
                                                                name="seconds"
                                                                value={formData.seconds}
                                                                OnChange={(e) => SetFormData(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={"col-50"}>
                                                                
                                                        <div className="form-group">
                                                            <label>
                                                                {t("ForLangs")}
                                                            </label>
                                                            <Select
                                                                value={formData.forLang}
                                                                OnChange={(e) => SetForLang(e.target.value)}
                                                            >
                                                                <option value="">{t("all")}</option>
                                                                {allLanguageMutations.map((item) => (
                                                                    <option 
                                                                        key={item.languageID} 
                                                                        value={item.suffix}
                                                                    >
                                                                        {item.suffix}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className={"col-100"}>
                                                                
                                                <div className="form-group">
                                                    <label>
                                                        {t("Link")} ({formData.selectedLang})
                                                    </label>
                                                    <Input
                                                        name="url"
                                                        value={GetFormLangValue("url")}
                                                        OnChange={(e) => SetFormLangData(e)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditBanner()}
                >
                    {(selectedBannerID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditBanner;