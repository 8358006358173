import React, { useState, useContext, createContext, useEffect } from 'react';
import md5 from 'md5';
import { useApolloClient } from '@apollo/client';
import { GET_ADMIN_USER_CONTEXT } from '../Queries/auth';

const authContext = createContext();
export const tokenName = md5("apttp_!@~`|=-65QhmZm733Dhfgk'§_-11!§");

export const AuthProvider = ({children}) => {

    const auth = UseProviderAuth({
        adminUserID:0,
        adminRoleID:0,
        name:"",
        surname:"",
        email:"",
        lang:""
    });

    return(
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export const UseAuth = () => {
    return useContext(authContext);
}

const UseProviderAuth = (adminInit) => {

    const client = useApolloClient()

    const [user,SetUser] = useState(adminInit);

    const [token,SetToken]     = useState(null);
    const [loading,SetLoading] = useState(true);
    const [showAuth,SetShowAuth] = useState(false);

    useEffect(() => {
        var t = localStorage.getItem(tokenName);
        if(!t) t = null;
        SetToken(t);
        SetLoading(false);
    },[])

    const SaveToken = (token) => {
        localStorage.setItem(tokenName,token);
        SetToken(token);
    }

    const Logout = () => {
        SetUser(adminInit);
        SetShowAuth(true);
        localStorage.removeItem(tokenName);
        SetToken(null);
        
    }

    const IsSignedIn = () => {
        return !!token;
    }

    const GetAdminUserContext = (OnExists,OnNotExists) => {

        client.query({
            query:GET_ADMIN_USER_CONTEXT,
            fetchPolicy: 'network-only',
        }).then((d) => {	

            if(d.data.AdminUserContext && d.data.AdminUserContext.adminUserID && token)
            {
                SetUser({
                    adminUserID:d.data.AdminUserContext.adminUserID,
                    adminRoleID:d.data.AdminUserContext.adminRoleID,
                    name:d.data.AdminUserContext.name,
                    surname:d.data.AdminUserContext.surname,
                    email:d.data.AdminUserContext.email,
                    lang:d.data.AdminUserContext.lang
                });

                if(OnExists) 
                    OnExists();
            }
            else if(OnNotExists)
                OnNotExists();

        }).catch((err) => console.log(err))
    }

    return {
        loading,
        token,
        showAuth,
        user,
        SaveToken,
        IsSignedIn,
        Logout,
        SetShowAuth,
        SetUser,
        GetAdminUserContext
    }

}