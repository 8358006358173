import { ApolloClient, InMemoryCache, ApolloProvider,ApolloLink,concat } from '@apollo/client';
import Routes from './Routes/index.js'; 
import {GRAPHQL_SERVER_URL} from './Config/index';
import { createUploadLink } from 'apollo-upload-client';
import { AuthProvider,tokenName } from './Modules/Auth/Library/UseAuth.js';
import { NotificationProvider } from './Modules/Notification/Library/UseNotification.js';
import { ContentManagerProvider } from './Modules/ContentManager/Library/UseContentManager.js';

const App = () => {

    const cache = new InMemoryCache();

    const uploadLink = new createUploadLink({ 
        uri: GRAPHQL_SERVER_URL,  
    });

    const link = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
          headers: {
            authorization: localStorage.getItem(tokenName) || null,
            'Apollo-Require-Preflight': 'true'
          }
        });
      
        return forward(operation);
    });

    const client = new ApolloClient({
        cache,
        link:concat(link, uploadLink)
    });
  
    return (
        <ApolloProvider client={client}>
            <NotificationProvider>
                <AuthProvider>
                    <ContentManagerProvider>
                        <Routes />
                    </ContentManagerProvider>
                </AuthProvider>
            </NotificationProvider>
        </ApolloProvider>
    );
    
}

export default App;