import { useQuery } from "@apollo/client";
import { GET_LINK,GET_LINKS } from "../Queries/link";
import { useState } from "react";
import { CompleteLangsArray } from "../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import { SERVER_URL,LINK_IMAGE_URL} from '../../../Config/index';

const UseGetLink = (selectedLinkID,lang,isEshopCategory,CallBack) => {

    const {t} = useTranslation();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allParameters, SetAllParameters] = useState([]);
    const [allLinks, SetAllLinks] = useState([]);

    const {loading:linkLoading,error:linkError} = useQuery(GET_LINKS,{
        variables:{
            lang,
            isEshopCategory: (isEshopCategory ? 1 : 0)
        },
        onCompleted:(d) => {

            var allLinks = [{linkID:"0",name:t("Main")},...d.AllLinks]
            SetAllLinks(allLinks);
        }
    });

    const {loading,error} = useQuery(GET_LINK,{
        variables:{
            linkID:selectedLinkID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",                    
                ownUrl:"", 
                description:"",                   
                metaTitle:"",                    
                metaKeywords:"",                    
                metaDescription:"",                    
                metaImage:null,
                metaImageShow:"", 
            }

            if(selectedLinkID != 0)
            {
                const uploadParamNameUrl = SERVER_URL + "/" + LINK_IMAGE_URL + "/fb_";
                let langs = CompleteLangsArray(d.LinkWithLangs.langs,d.AllLanguageMutations,emptyLang,"metaImage",uploadParamNameUrl);

                var selectedParameters = [];
                for(let val of d.LinkWithLangs.parameters)
                {
                    selectedParameters.push({
                        parameterID: val.parameterID,
                        name: val.parameter.name
                    });
                }

                CallBack({
                    active:                  d.LinkWithLangs.active,
                    parentID:                d.LinkWithLangs.parentID,
                    inHeader:                d.LinkWithLangs.inHeader,
                    inFooter:                d.LinkWithLangs.inFooter,
                    isBlog:                  d.LinkWithLangs.isBlog,
                    showOnlyInStockProducts: d.LinkWithLangs.showOnlyInStockProducts,
                    hideMenu:                d.LinkWithLangs.hideMenu,
                    usePriceFilter:          d.LinkWithLangs.usePriceFilter,
                    useDepthFilter:          d.LinkWithLangs.useDepthFilter,
                    useInStockFilter:        d.LinkWithLangs.useInStockFilter,
                    useWidthFilter:          d.LinkWithLangs.useWidthFilter,
                    linkID:                  selectedLinkID,
                    langs:                   langs,
                    isEshopCategory:         d.LinkWithLangs.isEshopCategory,
                    photo:                   null,
                    shortcut:                d.LinkWithLangs.shortcut,
                    mainPhoto:               d.LinkWithLangs.photo,
                    primarilySortBy:         d.LinkWithLangs.primarilySortBy,
                    selectedParameters:      selectedParameters
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    isEshopCategory:(isEshopCategory ? 1 : 0),
                    langs:langs,
                    mainPhoto:"",
                    primarilySortBy:"pri"
                });
            }

            SetAllParameters(d.AllParameters);
            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading: (loading || linkLoading),
        error: (error || linkError),
        allLanguageMutations,
        allLinks,
        allParameters
    }

}

export default UseGetLink;