import React from 'react';

const Warning = ({text}) => {
    return (
    	<div className="alert alert-warning text-center">	
    		{text}
    	</div>
    );
}

export default Warning;