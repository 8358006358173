import { useMutation } from "@apollo/client";
import { COPY_PRODUCT } from "../../Queries/products";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseCopyProduct = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [CpProduct] = useMutation(COPY_PRODUCT,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d); 

            const notifyText = t("NowIHaveTwin");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    
    const CopyProduct = (productID) => {

        CpProduct({
            variables:{
                productID
            }
        })
    } 

    return {
        CopyProduct
    }

}

export default UseCopyProduct;