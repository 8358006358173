import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { DELETE_VIDEOS, GET_ALL_VIDEOS } from "../../Queries/fileManager";

const UseDeleteVideos = (client) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);
    const [selectedItems, SetSelectedItems] = useState([]);

    const [DelVideos] = useMutation(DELETE_VIDEOS,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            
            var IDs = d.RemoveContentManagerVideos.split(",");

            const { AllContentManagerVideos } = client.readQuery({ query: GET_ALL_VIDEOS });
            
            var newArray = [...AllContentManagerVideos];
            for(let j in IDs)
            {
                for(let i in newArray)
                {
                    if(newArray[i].contentManagerVideoID == IDs[j])
                        newArray.splice(i,1); 
                } 
            }

            client.writeQuery({ 
                query:GET_ALL_VIDEOS,
                data:{
                    AllContentManagerVideos: newArray
                } 
            });

            SetSelectedItems([]);

        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedImages"),false);
        }
    }

    const DeleteVideos = (action) => {

        if(action)
        {              
            DelVideos({
                variables:{
                    contentManagerVideoIDs:selectedItems
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    const SelectVideo = (cmVideoID) => {

        var arr = [...selectedItems];

        var index = arr.indexOf(cmVideoID);
        if(index != -1)
            arr.splice(index,1);
        else
            arr.push(cmVideoID);

        SetSelectedItems(arr);
    }

    return{
        selectedItems,
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteVideos,
        SelectVideo
    }
}

export default UseDeleteVideos;