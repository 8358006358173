import Modal from 'react-modal';
import ModalHeader from './Modal/ModalHeader';
import Button from './Button';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const ModalNotification = ({text,yesNo,biggerNo,CallBack}) => {

	const {t} = useTranslation();
	
    return (
    	<Modal
	        closeTimeoutMS={150}
			isOpen={true}
            onRequestClose={() => CallBack(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
	    >
			<ModalHeader 
                title = {t("Warning")}
                OnClose ={() => CallBack(false)}
            />

			<div className="modal-body">
			
				{yesNo ? 
					<p className="no-margin">{text}</p> 
				: 
					<div className="alert alert-danger">{text}</div>
				}	
					
			</div>
			{yesNo ? 
				<div className="modal-footer"> 
					{biggerNo ?
						<div className="row">
							<div className="col-75">
								<Button className="btn-brown w-100" OnClick={() => CallBack(false)}>
									{t("NO")}
								</Button>
							</div>
							<div className="col-25">
								<Button className="btn-primary w-100" OnClick={() => CallBack(true)}>
									{t("YES")}
								</Button>
							</div>
						</div>
					:
						<div className="row">
							<div className="col-75">
								<Button className="btn-primary w-100" OnClick={() => CallBack(true)}>
									{t("YES")}
								</Button>
							</div>
							<div className="col-25">
								<Button className="btn-brown w-100" OnClick={() => CallBack(false)}>
									{t("NO")}
								</Button>
							</div>
						</div>
					}
				</div>
			:
				<div className="modal-footer"> 
					<Button className="btn-danger" onClick={() => CallBack(false)}>
						{t("Close")}
					</Button>
				</div>
			}
	       
	    </Modal>
    );
}


export default ModalNotification;
