import LoadingIcon from './LoadingIcon';

const Loading = () => {
	
    return (
        <div className="loading">
            <LoadingIcon />
        </div> 	
    );
  
}
export default Loading;