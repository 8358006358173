import {useQuery} from '@apollo/client';
import { GET_LINKS } from '../Queries/link';
import YesNo from "../../../GlobalComponents/YesNo";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllLinks = (lang,isEshopCategory) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedLinkIDs,SetSelectedLinkIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_LINKS,{
        variables:{
            lang,
            isEshopCategory: (!isEshopCategory ? 0 : 1)
        },
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllLinks);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: (isEshopCategory ? t("CategoryName") : t("LinkName")),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static text-center"
        },
        {
            value: t("InHeader"),
            className:"static text-center"
        },
        
    ];

    if(!isEshopCategory)
    {
        headerData.push({
            value: t("InFooter"),
            className:"static text-center"
        });
    }
    else
    {
        headerData.push({
            value: t("OnlyInStockProducts"),
            className:"static text-center"
        });
    }

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value: <YesNo isYes = {!!val.active} />,
                        className:"static text-center"
                    },
                    {
                        value: <YesNo isYes = {!!val.inHeader} />,
                        className:"static text-center"
                    }
                ],
            }

            if(!isEshopCategory)
            {
                cData.data.push({
                    value: <YesNo isYes = {!!val.inFooter} />,
                    className:"static text-center"
                })
            }
            else
            {
                cData.data.push({
                    value: <YesNo isYes = {!!val.showOnlyInStockProducts} />,
                    className:"static text-center"
                })
            }

            cData.rowID    = val.linkID;
            cData.parentID = val.parentID;
            cData.selected = false;

            if(val.subLink)
                cData.subData = GetContentData(val.subLink);

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,linkID) => {

        var checked = e.target.checked;
        var newSelectedLinkIDs = [...selectedLinkIDs];
        const newList = SelectLinkID(content,linkID,checked,newSelectedLinkIDs)

        SetContent(newList);
        SetSelectedLinkIDs(newSelectedLinkIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedLinkIDs = [];
        const newList = SelectLinkID(content,"all",checked,newSelectedLinkIDs)

        SetSelectedLinkIDs(newSelectedLinkIDs);
        SetContent(newList);
    }

    const SelectLinkID = (list,linkID,checked,selectedLinkIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(linkID == newList[i].rowID || linkID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedLinkIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedLinkIDs.length; j++)
                    {
                        if(selectedLinkIDs[j] == newList[i].rowID)
                            selectedLinkIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectLinkID(newList[i].subData,linkID,checked,selectedLinkIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedLinkIDs,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllLinks;