import { Fragment } from 'react';
import Modal from 'react-modal';
import Iframe from './Iframe';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { UseContentManager } from '../Library/UseContentManager';
import { useTranslation } from 'react-i18next';
import { GetSectionClassName,GetSectionStyles,GetContainerStyles } from '../Library/functions';
import SectionHelpers from './Section/SectionHelpers';
import ControlsMenu from './ControlsMenu';
import AddContent from './AddContent';
import Settings from './Settings/Settings';
import Columns from './Elements/Columns';
import Text from './Elements/Text';
import FileManager from './FIleManager/FileManager';
import Headline from './Elements/Headline';
import Image from './Elements/Image';
import ImageGallery from './Elements/ImageGallery';
import Video from './Elements/Video';
import RecommendedProducts from './Elements/RecommendedProducts';
import TopCategories from './Elements/TopCategories';
import Banner from './Elements/Banner';
import ButtonElement from './Elements/Button';
import Loading from '../../../GlobalComponents/Loading';
import Button from '../../../GlobalComponents/Button';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Select from '../../../GlobalComponents/Select';
import NewestArticles from './Elements/NewestArticles';
import Alert from './Elements/Alert';
import Html from './Elements/Html';
import Retino from './Elements/Retino';
import Form from './Elements/Form';

const ContentManager = () => {

    const {t} = useTranslation();
    const {
        formData,
        loading,
        allLanguageMutations,
        contentManagerLinkID,
        showHelpers,
        SetFormData,
        showCloseContentWarning,
        SaveContent,
        CloseContentManager,
        CheckContentManagerBeforeClose,
        CopyLangContent,
        ClearSelection
    } = UseContentManager();

    const {selectedLang,langForCopyContent,content} = formData;

    return(
        <Modal
            isOpen={!!contentManagerLinkID}
            onRequestClose={() => CheckContentManagerBeforeClose()}
            overlayClassName={"modal-overlay"}
            className={"modal-content maxi"}
        >

            <ModalHeader 
                title = {t("ContentManager")}
                allLanguages = {allLanguageMutations}
                OnClose ={() => CheckContentManagerBeforeClose()}
                selectedLang = {selectedLang}
                OnLangSelect = {(e) => {
                    ClearSelection();
                    SetFormData(e);
                }}
            >
                <Button 
                    className = "btn-primary"
                    OnClick = {() => SaveContent()}
                >{t("SaveContent")}</Button> 

            </ModalHeader>

            <AddContent />
            <Settings />
            <FileManager />

            {showCloseContentWarning ?
                <ModalNotification
                    yesNo = {true}
                    text = {t("YouMadeSomeChanges")}
                    biggerNo = {true}
                    CallBack = {(act) => CloseContentManager(act)}
                />
            :null}

            {loading ? 
                <Loading />
            :
                <Iframe>             
                    {content[selectedLang] && content[selectedLang].sections.map((item,index) => {

                        var containerStyles  = GetContainerStyles(item);
                        var sectionClassName = GetSectionClassName(item,content[selectedLang].sections.length);
                        
                        var stylesData       = GetSectionStyles(item);
                        sectionClassName += " " + stylesData.styleClassName;

                        return(
                            <section 
                                key = {"i - " + index}
                                id = {item.sectionName} 
                                className = {sectionClassName} 
                                style = {stylesData.styles} 
                            >

                                <div className={containerStyles.styleClassName + (item.fullWidth == 1 ? " cm-container-fluid"  : " cm-container")} style = {containerStyles.styles}>
                                    {item.elements && item.elements.map((elmItem,elmIndex) => {

                                        return(
                                            <Fragment key = {elmIndex}>
                                                {elmItem.columns &&
                                                    <Columns 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.columns} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.text &&
                                                    <Text 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.text} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.headline &&
                                                    <Headline 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.headline} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.image &&
                                                    <Image 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.image} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.imageGallery &&
                                                    <ImageGallery 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.imageGallery} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.video &&
                                                    <Video 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.video} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.recommendedProducts &&
                                                    <RecommendedProducts 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.recommendedProducts} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.topCategories &&
                                                    <TopCategories 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.topCategories} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.banner &&
                                                    <Banner 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.banner} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.button &&
                                                    <ButtonElement 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.button} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.newestArticles &&
                                                    <NewestArticles 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.newestArticles} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.alert &&
                                                    <Alert 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.alert} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.html &&
                                                    <Html 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.html} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.retino &&
                                                    <Retino 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.retino} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                                {elmItem.form &&
                                                    <Form 
                                                        elmIndex = {elmIndex} 
                                                        elementsCount = {item.elements.length}
                                                        data = {elmItem.form} 
                                                        lastIndexes = {index + "-" + elmIndex} 
                                                    />
                                                }
                                            </Fragment>
                                        )

                                    })}
                                </div>

                                {content[selectedLang].sections.length == 1 && item.elements.length == 0 &&
                                    <div className="cm-start-info">
                                        <p>{t("ToAddNewContent")}</p>
                                        <p>nebo</p>
                                        <p>zkopírujte obsah z jiného jazyka</p>
                                        <div className="copy-container">
                                            <Select 
                                                name="langForCopyContent"
                                                value={langForCopyContent}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                {allLanguageMutations.map((item) => (
                                                    <option key={item.languageID} value = {item.suffix}>{item.suffix}</option>
                                                ))}
                                            </Select>
                                            <button onClick = {() => CopyLangContent()} className="cm-button primary">
                                                    Zkopírovat
                                            </button>
                                        </div>
                                    </div>
                                }

                                {showHelpers ?
                                    <>
                                        <SectionHelpers index = {index} addTitle={t("AddSection")} />
                                        <ControlsMenu 
                                            element = "section"
                                            index = {index} 
                                            elementsCount = {content[selectedLang].sections.length}
                                            position = {index + 1}
                                            showAddContent = {true}
                                        />
                                    </>
                                :null}  

                                {item.fadeWithBackground == 1 ?
                                    <>
                                        <div className="cm-section-fade"></div>
                                        <div className="cm-section-fade bottom"></div>
                                    </>
                                :null}

                            </section>
                        )
                        
                    })}

                </Iframe>
            }
            
        </Modal>
    )
}

export default ContentManager;