import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins,GetElementFontSize } from "../../Library/functions";

const Html = ({data,lastIndexes,elmIndex,elementsCount}) => {

    console.log(data);

    const {showHelpers,indexToShowMenu,SelectElement,SetSettings} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins   = GetElementMargins(data);
    var className = margins.styleClassName;

    const OnHtmlChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"code",content,"html")
    }

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "html"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={className + " " + data.className + " " + margins.className} style={margins.styles} >
                {data.type == "html" ?
                    <textarea style={{width:"100%"}} placeholder="HTML kód" value={data.code} name="code" onChange={(e) => OnHtmlChange(e.target.value)}></textarea>
                :
                    <div dangerouslySetInnerHTML={{__html:data.code}}></div>
                }
            </div>

            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Html;