import letterH from '../../../Media/Images/Icons/letter-h.webp';
import columns from '../../../Media/Images/Icons/columns.webp';
import text from '../../../Media/Images/Icons/paragraph.webp';
import button from '../../../Media/Images/Icons/button.webp';
import image from '../../../Media/Images/Icons/image.webp';
import imageGallery from '../../../Media/Images/Icons/image_gallery.webp';
import video from '../../../Media/Images/Icons/video.webp';
import articles from '../../../Media/Images/Icons/articles.webp';
import newsletter from '../../../Media/Images/Icons/newsletter.webp';
import form from '../../../Media/Images/Icons/form.webp';
import faq from '../../../Media/Images/Icons/faq.webp';
import attention from '../../../Media/Images/Icons/attention.webp';
import recommendedProducts from '../../../Media/Images/Icons/recommended_products.webp';
import banner from '../../../Media/Images/Icons/banner.webp';
import category from '../../../Media/Images/Icons/category.webp';
import html from '../../../Media/Images/Icons/html.webp';
import letterR from '../../../Media/Images/Icons/letter_r.webp';
import { UseContentManager } from '../Library/UseContentManager';
import Sidebar from './Sidebar';
import content from '../../../Media/Images/Icons/content.webp';
import { useTranslation } from "react-i18next";
import { 
    COLUMNS,
    TEXT,
    HEADLINE,
    IMAGE,
    IMAGE_GALLERY,
    RECOMMENDED_PRODUCTS,
    TOP_CATEGORIES,
    BANNER,
    BUTTON,
    VIDEO,
    NEWEST_ARTICLES,
    ALERT,
    HTML,
    RETINO,
    FORM
} from '../Library/elements';

const AddContent = () => {

    const {t} = useTranslation();
    const {indexToAddContent,AddElement,SetIndexToAddContent} = UseContentManager();

    var isOpen  = false
    if(indexToAddContent || indexToAddContent == 0)
        isOpen = true;

    return(
        <>
            <Sidebar 
                isOpen = {isOpen}
                headerIcon = {content}
                headerTitle = {t("AddContent")}
                contentClassName={"no-padding"}
                OnCloseSidebar={() => SetIndexToAddContent(null)}
            >
                <div className="row no-margin">
                    <div onClick={() => AddElement(""+indexToAddContent+"",COLUMNS)} className="cm-add-element">
                        <img src={columns} />
                        <h3>Sloupce</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",HEADLINE)} className="cm-add-element">
                        <img src={letterH} />
                        <h3>Nadpis</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",TEXT)} className="cm-add-element">
                        <img src={text} />
                        <h3>Text</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",IMAGE)} className="cm-add-element">
                        <img src={image} />
                        <h3>Obrázek</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",IMAGE_GALLERY)} className="cm-add-element">
                        <img src={imageGallery} />
                        <h3>Fotogalerie</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",VIDEO)} className="cm-add-element">
                        <img src={video} />
                        <h3>Video</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",BUTTON)} className="cm-add-element">
                        <img src={button} />
                        <h3>Tlačítko</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",BANNER)} className="cm-add-element">
                        <img src={banner} />
                        <h3>Bannery</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",RECOMMENDED_PRODUCTS)} className="cm-add-element">
                        <img src={recommendedProducts} />
                        <h3>Doporučené produkty</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",TOP_CATEGORIES)} className="cm-add-element">
                        <img src={category} />
                        <h3>Top kategorie</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",NEWEST_ARTICLES)} className="cm-add-element">
                        <img src={articles} />
                        <h3>Nejnovější články</h3>
                    </div>
                    {false ?
                        <div className="cm-add-element">
                            <img src={newsletter} />
                            <h3>Newsletter</h3>
                        </div>
                    :null}
                    <div onClick={() => AddElement(""+indexToAddContent+"",FORM)} className="cm-add-element">
                        <img src={form} />
                        <h3>Formulář</h3>
                    </div>
                    {false ?
                        <div className="cm-add-element">
                            <img src={faq} />
                            <h3>FAQ</h3>
                        </div>
                    :null}
                    <div onClick={() => AddElement(""+indexToAddContent+"",ALERT)} className="cm-add-element">
                        <img src={attention} />
                        <h3>Upozornění</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",HTML)} className="cm-add-element">
                        <img src={html} />
                        <h3>HTML</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",RETINO)} className="cm-add-element">
                        <img src={letterR} />
                        <h3>Retino</h3>
                    </div>
                </div>
            </Sidebar>
        </>
    )
}

export default AddContent;