import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins } from "../../Library/functions";
import bannerIcon from '../../../../Media/Images/Icons/banner.webp';
import { useTranslation } from "react-i18next";

const Banner = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();
    const {t} = useTranslation();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
    
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "banner"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={"cm-banner-margin " + margins.styleClassName +  " " + data.className} style={margins.styles}>
                
                <div className="cm-start-info big">
                    <img src={bannerIcon} alt="" />
                    {t("HereWillBeChosenBanners")}
                </div>
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Banner;