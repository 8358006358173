import gql from 'graphql-tag';

export const UPLOAD_FB_PHOTOS = gql`
    mutation UploadFbPhotos(
        $files:[Upload]
    ){
        UploadFbPhotos(
            files:$files
        ){
            fbPhotoID
            name
            url
        }
    }
`;

export const EDIT_FB_PHOTOS = gql`
    mutation EditFbPhotos($fbPhotos:[FbPhotoInput]){
        EditFbPhotos(fbPhotos:$fbPhotos)
    }
`;

export const DELETE_FB_PHOTOS = gql`
    mutation DeleteFbPhotos($fbPhotoIDs:[ID]){
        DeleteFbPhotos(fbPhotoIDs:$fbPhotoIDs)
    }
`;

export const GET_FB_PHOTOS = gql`
    query AllFbPhotos{
        AllFbPhotos{
            fbPhotoID
            name
            url
        }
    }
`;